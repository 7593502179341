//VARIABLES
$primary: #f73718;
$secondary: #12674a;
$backGround: #e9e1db;
$dark: #281713;
$light: #fff;
$delete: #d04848;
$configIconColor: #f8f4ec;
$boxShadow: -7px 10px 5px 1px rgb(168, 168, 168);
$emergencyAlertColor: #d04848;
$testAlertColor: #fc6736;
$buttonBgc: #f0f0f0;

//RES
$large: 1290px;
$desktopMax: 1289.98px;
$desktopMin: 992px;
$tabletMax: 991.98px;
$tabletMin: 768px;
$mobileMax: 767.98px;
$mobileMin: 576px;
$tiny: 575.98px;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: "Rale";
  src: url(../fonts/Raleway-VariableFont_wght.ttf);
}
html {
  font-size: 62.5%;
}
body {
  scroll-behavior: smooth;
}

.container {
  position: relative;
  min-height: 100vh;
  background-color: $backGround;
  font-family: Rale;
  padding-bottom: 10rem;
  z-index: 0;
  .header {
    position: fixed;
    width: 100%;
    z-index: 1;
    background-color: $backGround;
    .header__bottom_width {
      width: 100%;
      height: 0.3rem;
      background-color: $dark;
    }
    .header__content {
      @media (min-width: $large) {
        max-width: 1290px !important;
        margin: 0 auto;
      }
      @media (min-width: $desktopMin) and (max-width: $desktopMax) {
        max-width: 950px !important;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        gap: 3rem;
      }
      @media (min-width: $tabletMin) and (max-width: $tabletMax) {
        max-width: 750px !important;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        gap: 3rem;
      }
      @media (min-width: $mobileMin) and (max-width: $mobileMax) {
        max-width: 550px !important;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        gap: 5rem;
      }
      @media (max-width: $tiny) {
        max-width: 550px !important;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        gap: 2rem;
        padding: 2rem 0rem 4rem 0rem;
      }
      padding: 5rem 0rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .header__headline {
        @media (min-width: $tabletMin) and (max-width: $tabletMax) {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-self: center;
          gap: 3rem;
        }
        @media (min-width: $mobileMin) and (max-width: $mobileMax) {
          max-width: 550px !important;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 5rem;
        }
        @media (max-width: $tiny) {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-self: center;
          gap: 2rem;
        }
        display: flex;
        align-items: center;
        gap: 5rem;
        .headline__logo {
          position: relative;
          .headline__title {
            font-size: 5rem;
            font-weight: 900;
            color: $light;
            text-transform: uppercase;
            padding: 1rem;
            background-color: $primary;
            border-radius: 1rem;
            @media (max-width: $tabletMax) {
              cursor: pointer;
              margin-bottom: 1rem;
            }
          }
          .plus__icon_mobile {
            position: absolute;
            right: -7px;
            bottom: 0;
            background-color: $secondary;
            padding: 0.5rem;
            border-radius: 50%;
            .plus__icon {
              font-size: 2rem;
              color: $light;
            }
          }
        }
        .headline__create {
          display: flex;
          align-items: center;
          gap: 2rem;
          width: 100%;
          background-color: $light;
          justify-content: space-between;
          align-items: center;
          padding: 1.5rem 2rem;
          border-radius: 1rem;
          box-shadow: $boxShadow;
          @media (max-width: $mobileMax) {
            flex-direction: column;
            justify-content: center;
          }
          .create__input_container {
            width: 60%;
            display: flex;
            gap: 3rem;
            @media (max-width: $mobileMax) {
              flex-direction: column;
              justify-content: center;
              width: 100%;
            }
            .create__device {
              width: 13rem;
              @media (max-width: $mobileMax) {
                width: 25rem;
              }
            }
            .create__owner {
              width: 25rem;
            }
            input {
              background-color: transparent;
              outline: none;
              border: none;
              padding: 1rem 2rem;
              font-size: 2rem;
              font-weight: 900;
              border-bottom: 0.2rem solid $dark;
            }
          }
          .create__btn {
            background-color: $secondary;
            outline: none;
            border: $light;
            width: 5rem;
            height: 5rem;
            border-radius: 50%;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 1rem;
            @media (max-width: $mobileMax) {
              width: 100%;
              border-radius: 1rem;
              margin-left: 0;
              margin-top: 2rem;
            }
            .create__btn_icon {
              font-size: 4rem;
              color: $light;
            }
          }
        }
        .more__than_mobile {
          @media (max-width: $tabletMax) {
            display: none;
          }
        }
      }
      .header__menu {
        display: flex;
        justify-content: center;
        gap: 5rem;
        .config__wifi_box {
          position: relative;
          &:hover {
            transition: all ease-in-out 0.3s;
            cursor: pointer;
            .config__icon_container {
              background-color: $secondary;
              transition: all ease-in-out 0.3s;
              .config__icon {
                color: $light;
              }
            }
            .item__wifi {
              display: block;
              position: absolute;
              top: 5rem;
              transition: all ease-in-out 0.3s;
            }
          }
          .config__icon_container {
            background-color: $configIconColor;
            outline: none;
            border: 0.2rem solid $secondary;
            width: 5rem;
            height: 5rem;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: all ease-in-out 0.3s;
            .config__icon {
              font-size: 2rem;
              color: $secondary;
            }
          }
          .item__wifi {
            position: absolute;
            display: none;
            top: 6rem;
            right: 0;
            // opacity: 0;
            padding: 1rem 0;
            z-index: 99;
            transition: all ease-in-out 0.3s;
            @media (max-width: $tiny) {
              right: -12rem;
            }
            .item__wifi_content {
              background-color: $light;
              padding: 2rem 3rem;
              display: flex;
              flex-direction: column;
              gap: 1rem;
              border-radius: 2rem;
              border: 2px solid $dark;
              input {
                background-color: transparent;
                outline: none;
                border: none;
                padding: 1.5rem;
                font-size: 1.5rem;
                font-weight: 600;
                border-bottom: 0.2rem solid $dark;
              }
              .item__config_btn {
                background-color: $secondary;
                padding: 1rem 0rem;
                border-radius: 3rem;
                color: $light;
                font-weight: 600;
                border: 0.2rem solid $secondary;
                font-size: 1.5rem;
                cursor: pointer;
                margin-top: 2.5rem;
              }
            }
          }
        }
        .config__pri_box {
          position: relative;
          &:hover {
            transition: all ease-in-out 0.3s;
            .item__pri {
              display: block;
              position: absolute;
              top: 5rem;
              transition: all ease-in-out 0.3s;
              opacity: 1;
            }
            .config__icon_container {
              background-color: $secondary;
              transition: all ease-in-out 0.3s;
              .config__icon {
                color: $light;
              }
            }
          }
          .config__icon_container {
            background-color: $configIconColor;
            outline: none;
            border: 0.2rem solid $secondary;
            width: 5rem;
            height: 5rem;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: all ease-in-out 0.3s;
            .config__icon {
              font-size: 2rem;
              color: $secondary;
            }
          }
          .item__pri {
            position: absolute;
            top: 5rem;
            right: 0;
            // opacity: 0;
            display: none;
            // display: block;
            padding: 1rem 0;
            z-index: 99;
            transition: all ease-in-out 0.3s;
            width: 48rem;
            @media (max-width: $mobileMax) {
              right: -10rem;
            }
            @media (max-width: $tiny) {
              right: -10rem;
              width: 30rem;
            }
            .item__pri_content {
              background-color: $light;
              padding: 2rem;
              display: flex;
              flex-direction: column;
              gap: 1rem;
              border-radius: 2rem;
              border: 2px solid $dark;
              .item__pri_input_container {
                input {
                  background-color: transparent;
                  outline: none;
                  border: none;
                  padding: 1.5rem;
                  font-size: 1.5rem;
                  font-weight: 600;
                  width: 100%;
                  border-bottom: 0.2rem solid $dark;
                }
              }
              .item__pri_list {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                gap: 2rem;
                padding: 2rem 0;
                @media (max-width: $tiny) {
                  gap: 1rem;
                }
                .pri__item {
                  width: 45%;
                  display: flex;
                  align-items: center;
                  gap: 1rem;
                  background-color: #ededed;
                  padding: 1.5rem 3rem;
                  border-radius: 1rem;
                  transition: all ease-in-out 0.2s;
                  @media (max-width: $tiny) {
                    width: 48%;
                    padding: 1rem;
                  }
                  &:hover {
                    transition: all ease-in-out 0.2s;
                    background-color: $dark;
                    .pri__item_title {
                      color: $light;
                      transition: all ease-in-out 0.2s;
                    }
                  }
                  input {
                    cursor: pointer;
                  }
                  .pri__item_title {
                    font-size: 1.5rem;
                    font-weight: 900;
                    color: $dark;
                    transition: all ease-in-out 0.3s;
                    @media (max-width: $tiny) {
                      font-size: 1rem;
                      font-weight: 0;
                    }
                  }
                }
              }
              .item__config_btn {
                background-color: $secondary;
                padding: 1rem 0rem;
                border-radius: 3rem;
                color: $light;
                font-weight: 600;
                border: 0.2rem solid $secondary;
                font-size: 2rem;
                cursor: pointer;
                @media (max-width: $tiny) {
                }
              }
            }
          }
        }
        .import__container {
          &:hover {
            transition: all ease-in-out 0.3s;
            .import__btn {
              background-color: $secondary;
              transition: all ease-in-out 0.3s;
              .import__icon {
                color: $light;
                transition: all ease-in-out 0.3s;
              }
            }
          }
          .import__btn {
            background-color: $configIconColor;
            outline: none;
            border: 0.2rem solid $secondary;
            width: 5rem;
            height: 5rem;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: all ease-in-out 0.3s;
            .import__icon {
              font-size: 2rem;
              color: $secondary;
              transition: all ease-in-out 0.3s;
            }
          }
        }
      }
    }
    .fire {
      position: relative;
      z-index: 99;
      width: 100%;
      background-color: #d04848;
      font-size: 7rem;
      text-align: center;
      padding: 2rem 0;
      color: $light;
      display: flex;
      align-items: center;
      justify-content: center;
      .alert__id {
        font-size: 4rem;
        margin-left: 2rem;
      }
    }
    .test {
      position: relative;
      z-index: 99;
      width: 100%;
      background-color: #fc6736;
      font-size: 7rem;
      text-align: center;
      padding: 2rem 0;
      color: $light;
      display: flex;
      align-items: center;
      justify-content: center;
      .alert__id {
        font-size: 4rem;
        margin-left: 2rem;
      }
    }
    .config {
      position: relative;
      z-index: 99;
      width: 100%;
      background-color: #0d9276;
      font-size: 7rem;
      text-align: center;
      padding: 2rem 0;
      color: $light;
      text-transform: uppercase;
      @media (max-width: $mobileMax) {
        font-size: 3rem;
      }
    }
  }
  .content {
    @media (min-width: $large) {
      max-width: 1290px !important;
      margin: 0 auto;
    }
    @media (min-width: $desktopMin) and (max-width: $desktopMax) {
      max-width: 950px !important;
      margin: 0 auto;
      padding-top: 25rem;
    }
    @media (min-width: $tabletMin) and (max-width: $tabletMax) {
      max-width: 750px !important;
      margin: 0 auto;
      padding-top: 36rem;
    }
    @media (min-width: $mobileMin) and (max-width: $mobileMax) {
      max-width: 550px !important;
      margin: 0 auto;
      padding-top: 45rem;
    }
    @media (max-width: $tiny) {
      max-width: 550px !important;
      margin: 0 auto;
      padding-top: 25rem;
    }
    padding-top: 20rem;
    .find__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-items: center;
      padding: 3rem 0rem 8rem 0rem;
      @media (max-width: $mobileMin) {
        flex-direction: column;
        gap: 4rem;
        padding: 2rem 0rem 6rem 0rem;
      }
      .find__count {
        font-size: 2rem;
        color: $dark;
        font-weight: 600;
        display: flex;
        align-items: flex-end;
        .count__number {
          font-size: 3rem;
          margin-right: 1rem;
        }
      }
      .search_container {
        display: flex;
        gap: 2rem;
        align-items: center;
        justify-content: flex-end;
        input {
          padding: 1rem;
          font-size: 1.5rem;
          font-weight: 600;
          background-color: transparent;
          outline: none;
          border: none;
          border-bottom: 0.2rem solid $dark;
        }
        .search__btn {
          width: 4rem;
          height: 4rem;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          background-color: $secondary;
          border: none;
          .search__btn_icon {
            font-size: 1.5rem;
            color: $light;
          }
        }
        .clear__btn {
          width: 4rem;
          height: 4rem;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
          cursor: pointer;
          background-color: $light;
          .clear__btn_icon {
            font-size: 1.5rem;
            color: $secondary;
          }
        }
      }
    }
    .device__list {
      @media (min-width: $large) {
      }
      @media (min-width: $desktopMin) and (max-width: $desktopMax) {
      }
      @media (min-width: $tabletMin) and (max-width: $tabletMax) {
        justify-content: center;
        gap: 10rem;
      }
      @media (min-width: $mobileMin) and (max-width: $mobileMax) {
        justify-content: center;
        gap: 10rem;
      }
      @media (max-width: $tiny) {
        justify-content: center;
        gap: 10rem;
      }
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      gap: 14rem;
      .device {
        @media (min-width: $large) {
          width: 26%;
        }
        @media (min-width: $desktopMin) and (max-width: $desktopMax) {
          width: 40%;
        }
        @media (min-width: $tabletMin) and (max-width: $tabletMax) {
          width: 60%;
        }
        @media (min-width: $mobileMin) and (max-width: $mobileMax) {
          width: 80%;
        }
        @media (max-width: $tiny) {
          width: 80%;
        }
        position: relative;
        height: 61rem;
        width: 25%;
        background-color: $light;
        padding: 2rem;
        border-radius: 1.5rem;
        box-shadow: $boxShadow;
        .device__name {
          background-color: $secondary;
          width: 100%;
          font-size: 1.5rem;
          color: $light;
          text-align: center;
          padding: 1rem;
          border-radius: 1rem;
          font-weight: 600;
        }
        .device__info {
          display: flex;
          flex-direction: column;
          gap: 2rem;
          margin-top: 2rem;
          padding: 2rem 2.5rem;
          .line {
            border: 1px $dark;
            border-style: dashed;
          }
          .info__version {
            display: flex;
            align-items: center;
            gap: 2rem;
            width: 100%;
            justify-content: space-between;
            .info__name {
              font-size: 1.7rem;
              font-weight: 600;
              width: 30%;
            }
            .info__value {
              font-size: 2rem;
              font-weight: 900;
              display: flex;
              align-items: center;
              .bat {
                transform: rotate(270deg);
              }
              .low {
                color: $delete;
              }
              .half__full {
                color: #faa300;
              }
              .quar__full {
                color: #337357;
              }
              .full {
                color: #337357;
              }
              .bat__per {
                font-size: 1.5rem;
              }
            }
            .checked {
              color: $secondary;
            }
            .not__checked {
              color: $delete;
            }
          }
          .info__connection {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;
            .info__name {
              font-size: 1.7rem;
              font-weight: 600;
              width: 30%;
            }
            .info__conpri {
              .conpri__list {
                display: flex;
                flex-direction: column;
                gap: 2rem;
                .conn__active {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  gap: 2rem;
                  .info__value {
                    font-size: 1.5rem;
                    font-weight: 600;
                    padding: 0.5rem 2rem;
                    border-radius: 0.5rem;
                    font-weight: 600;
                    color: $dark;
                  }
                  .active {
                    background-color: $secondary;
                    color: $light;
                    font-weight: 900;
                    // border: 0.3rem solid $secondary;
                  }
                  .conn__status {
                    font-size: 1.5rem;
                  }
                  .conn__able {
                    color: $secondary;
                  }
                  .conn__disable {
                    color: $delete;
                  }
                }
              }
            }
          }
          .info__group {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            gap: 3rem;
            .info__name {
              font-size: 1.7rem;
              font-weight: 600;
              width: 30%;
            }
            .info__conpri {
              display: flex;
              align-items: center;
              gap: 2rem;
              width: 100%;
              justify-content: space-between;
              .info__name {
                font-size: 1.7rem;
                font-weight: 600;
                width: 30%;
              }
              .conpri__list {
                display: flex;
                .info__value {
                  font-size: 1.5rem;
                  font-weight: 600;
                  padding: 0.5rem 2rem;
                  border-radius: 0.5rem;
                  font-weight: 600;
                  color: $dark;
                }
                .active {
                  background-color: #fc9e70;
                  color: $light;
                }
              }
            }
          }
        }
        .welcome {
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .welcome__content {
            .welcome__text {
              text-align: center;
              font-size: 1.5rem;
              font-style: italic;
              font-weight: 600;
              margin-bottom: 2rem;
              color: #7e7e7e;
            }
          }
        }
        .device_detele {
          position: absolute;
          top: -1.3rem;
          right: -1.3rem;
          width: 3rem;
          height: 3rem;
          border-radius: 50%;
          background-color: $delete;
          color: $light;
          border: none;
          cursor: pointer;
          font-weight: 600;
          text-transform: uppercase;
        }
      }
      .emergency__alert {
        background-color: $emergencyAlertColor;
      }
      .test__alert {
        background-color: $testAlertColor;
      }
    }
  }
  .alert__box {
    width: 100%;
    padding-top: 10rem;
    background-color: transparent;
  }
  .alert__box_unactive {
    height: 10rem;
    width: 100%;
  }
}
